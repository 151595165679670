<template>
  <div>
    <v-chip color="transparent" small>
      <v-icon small left>mdi-message-text-outline</v-icon>
      <span>{{ messages.size + 1 }}</span>
    </v-chip>
    
    <v-chip color="transparent" small>
      <v-icon small left>mdi-paperclip</v-icon>
      <span>{{ attachments }}</span>
    </v-chip>
  </div>
</template>

<script>

export default {
  props: ['messages', 'attachments'],
}
</script>