<template>
  <div>
    <preloader v-if="status.getting" />

    <div v-if="!status.getting && !filterMessages.length" class="pa-7">
      <custom-alert>
        {{ user && ['employer', 'admin'].includes(user.role) ? 'No message thread found' : 'No contacts in Inbox. This feature will become available after a school messages you.' }}
      </custom-alert>
    </div>

    <template v-if="!status.getting && filterMessages.length">
      <message-summary
        v-for="message in orderBy(filterMessages, 'updated', 'desc')"
        :key="message.id"
        :message="message"
      />
    </template>
  </div>
</template>

<script>
import MessageSummary from './MessageSummary'
import { mapState, mapActions } from 'vuex'
import { orderBy } from 'lodash'

export default {
  /*------------------------------------------------------------------------------
   * MESSAGE SUMMARY
   *----------------------------------------------------------------------------*/
  components: { MessageSummary },

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: ['view'],

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy,
      latests: []
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      messages: state => state.message.messages,
      status: state => state.message.status,
      user: state => state.user.user,
    }),

    filterMessages: function () {
      if (this.view == 'main') {
        return this.messages.filter(message => {
          if (this.user.userid == message.sender) return !message.senderArchived
          else return !message.recipientArchived
        })
      }
      else if (this.view == 'archived') {
        return this.messages.filter(message => {
          if (this.user.userid == message.sender) return message.senderArchived
          else return message.recipientArchived
        })
      }
      else if (this.view == 'starred') {
        return this.messages.filter(message => {
          if (this.user.userid == message.sender) return message.senderStarred
          else return message.recipientStarred
        })
      }
      else {
        return []
      }
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    '$route': function () {
      this.getMessages()
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('message', [
      'getMessages',
      'starMessage',
      'archiveMessage'
    ]),
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getMessages()
  }
}
</script>