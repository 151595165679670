<template>
  <div class="flex-grow-1 pb-10">
    <AdminTitle title="Inbox">
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">
          <v-btn
            @click="$store.dispatch('message/getMessages')"
            :loading="$store.state.message.status.getting"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </template>
    </AdminTitle>

    <v-card class="shadow-lg">
      <v-row no-gutters>
        <v-col :style="{ maxWidth: $vuetify.breakpoint.xs ? '' : '270px' }" cols="12" md="3">
          <v-sheet class="pa-10 d-none d-sm-block">
            <v-btn @click="view = 'compose'" class="mb-5" color="accent gradient" rounded block>
              <v-icon left>mdi-plus</v-icon>
              Compose
            </v-btn>
           
            <v-list-item @click="view = 'main'" :input-value="view == 'main'" class="px-0 msg-menu-link">
              <v-list-item-title class="font-weight-bold heading-font">
                Message Threads ({{ countMain }})
              </v-list-item-title>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-list-item @click="view = 'starred'" :input-value="view == 'starred'" class="px-0 msg-menu-link">
              <v-list-item-title class="font-weight-bold heading-font">
                Starred Messages ({{ countStarred }})
              </v-list-item-title>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-list-item @click="view = 'archived'" :input-value="view == 'archived'" class="px-0 msg-menu-link heading-font">
              <v-list-item-title class="font-weight-bold">
                Archived ({{ countArchived }})
              </v-list-item-title>
            </v-list-item>
          </v-sheet>

          <v-sheet class="pa-3 d-flex align-center justify-center d-sm-none gap-10">
            <v-btn 
              @click="view = 'compose'" 
              fab x-small depressed
              color="accent gradient" 
              class="mb-5" 
            >
              <v-icon>mdi-file-edit-outline</v-icon>
            </v-btn>
            
            <v-badge color="primary" :content="countMain" :value="countMain" overlap>
              <v-btn 
                @click="view = 'main'" 
                :color="view == 'main' ? 'grey white--text' : 'default'"
                class="mb-5" 
                fab depressed x-small
              >
                <v-icon>mdi-email-outline</v-icon>
              </v-btn>
            </v-badge>
            
            <v-badge color="primary" :content="countStarred" :value="countStarred" overlap>
              <v-btn 
                @click="view = 'starred'" 
                :color="view == 'starred' ? 'grey white--text' : 'default'"
                class="mb-5" 
                fab depressed x-small
              >
                <v-icon>mdi-star-outline</v-icon>
              </v-btn>
            </v-badge>
            
            <v-badge color="primary" :content="countArchived" :value="countArchived" overlap>
              <v-btn 
                @click="view = 'archived'" 
                :color="view == 'archived' ? 'grey white--text' : 'default'"
                class="mb-5" 
                fab depressed x-small
              >
                <v-icon>mdi-archive-outline</v-icon>
              </v-btn>
            </v-badge>
          </v-sheet>
        </v-col>

        <v-col v-if="view == 'compose'" style="border-left: 1px solid rgba(10,30,35,0.1)" :cols="$vuetify.breakpoint.xs ? '12' : ''">
          <compose/>
        </v-col>

        <v-col :style="{ maxHeight: '900px', overflowY: 'scroll' }" v-if="view !== 'compose'"  style="border-left: 1px solid rgba(10,30,35,0.1)">
          <messages :view="view" />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import compose from '@/views/Message/Compose'
import messages from '@/views/Message/Messages'
import { mapState } from 'vuex'

export default {
  name: 'Message',

  metaInfo: {
    title: 'Inbox'
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    compose,
    messages
  },
  
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      view: 'main'
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      messages: state => state.message.messages,
      user: state => state.user.user,
    }),

    countMain: function () {
      let messages = this.messages.filter(message => {
        if (this.user && this.user.userid == message.sender) return !message.senderArchived
        else return !message.recipientArchived
      })

      return messages.length
    },

    countStarred: function () {
      let messages = this.messages.filter(message => {
        if (this.user && this.user.userid == message.sender) return message.senderStarred
        else return message.recipientStarred
      })

      return messages.length
    },
    
    countArchived: function () {
      let messages = this.messages.filter(message => {
        if (this.user && this.user.userid == message.sender) return message.senderArchived
        else return message.recipientArchived
      })

      return messages.length
    },
  },

}
</script>

<style lang="scss">

.msg-menu-link {
  opacity: 0.2 !important;
  transition: all 0.3s;

  &:hover,
  &.v-list-item--active {
    opacity: 1 !important;

    &:before {
      background: transparent !important;
    }
  }
}

.msg-block {
  border-bottom: 1px solid rgba(10,30,35,0.1);

  &:hover {
    background: #fafbfb;
  }
}

</style>