<template>
  <div v-if="user" class="pa-10">
    <div v-if="!recipient">
      <custom-alert
        v-if="!recipients.length && user.role !== 'admin'" 
        type="info" 
        border="left"
        text
      >
        {{ user && user.role == 'employer' ? 'Schools are only able to send messages to teachers who have applied for a job, the school has previously employed' : 'No contacts in Inbox. This feature will become available once a school invites you to a job' }}
      </custom-alert>

      <v-text-field
        v-model="search"
        v-if="messages.length || user.role == 'admin'"
        label="Search Recipient"
        append-icon="mdi-account-search"
        clearable
        outlined
        rounded
      ></v-text-field>

      <div v-if="recipients.length || user.role == 'admin'" class="d-flex flex-wrap gap-10">
        <v-hover
          v-for="recipient in recipients"
          :key="recipient.id"
          v-slot:default="{ hover }"
        >
          <v-card @click="setRecipient(recipient)" :class="{ 'shadow-md' : hover }" class="rounded-pill pr-2" outlined>
            <v-card-text class="d-flex align-center gap-10 py-2">
              <div>
                <UserPhoto
                  :id="recipient.userid"
                  photoSize="small"
                  size="30"
                />
              </div>

              <div>
                <div class="font-weight-medium primary--text">
                  {{ recipient.firstName }} {{ recipient.lastName }}
                  <div class="caption">{{ recipient.email }}</div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-hover>
      </div>

    </div>

    <v-form v-if="recipient && !sent" ref="messageForm">
      <v-card class="rounded-pill d-inline-block mb-5 pr-2" outlined>
        <v-card-text class="d-flex align-center gap-10 py-2">
          <div>
            <UserPhoto
              :id="recipient.userid"
              photoSize="small"
              size="30"
            />
          </div>

          <div>
            <div class="font-weight-medium primary--text">
              {{ recipient.firstName }} {{ recipient.lastName }}
              <div class="caption">{{ recipient.email }}</div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      
      <v-text-field
        v-model="data.subject"
        label="Subject"
        :rules="[rules.required]"
        outlined
      ></v-text-field>

      <v-textarea
        v-model="data.message"
        label="Message"
        :rules="[rules.required]"
        auto-grow
        outlined
      ></v-textarea>

      <v-file-input
        v-model="attachments"
        @change="filesSelected($event)"
        ref="attachments"
        class="d-none"
        multiple
      ></v-file-input>

      <div v-if="files.length" class="d-flex flex-wrap align-center gap-12 pb-5">
        <file-block
          v-for="(file, i) in files"
          :key="i"
          :file="file"
          @remove="$store.commit('message/removeAttachment', i)"
        />
      </div>

      <div class="d-flex gap-10 align-center">
        <v-btn
          @click="sendMessage()"
          :loading="status.creating"
          color="accent gradient"
        >
          Send
          <v-icon right small>mdi-send</v-icon>
        </v-btn>

        <v-btn
          @click="recipient = null; $emit('cancel')"
          :disabled="status.creating"
          text
        >Cancel</v-btn>

        <v-spacer></v-spacer>

        <v-btn @click="$refs.attachments.$refs.input.click()" :disabled="status.creating" color="rgba(10,30,35,0.16)" icon outlined>
          <v-icon color="primary">mdi-paperclip</v-icon>
        </v-btn>
      </div>
    </v-form>

    <v-alert :value="sent" type="success" border="left" text>
      Message sent
    </v-alert>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import rules from '@/rules'
import FileBlock from './FileBLock'

export default {
  props: ['toMessage', 'subject', 'job'],

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      recipient: null,
      getting: false,
      sent: false,
      attachments: [],
      search: null,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      users: state => state.users.users,
      data: state => state.message.data,
      status: state => state.message.status,
      files: state => state.message.attachments,
      messages: state => state.message.messages,
      applicants: state => state.apply.applicants,
      invites: (state) => state.invite.inviteds,
    }),

    recipients: function () {
      let users = this.users

      if (this.user && this.user.role == 'employer') {
        users = users.filter(user => {
          return !!this.applicants.find(a => a.userid == user.userid)
        })

        users = users.filter(user => {
          return !!this.invites.find(i => i.talentId == user.userid)
        })
      }
      
      if (this.user && this.user.role == 'jobseeker') {
        users = users.filter(user => {
          return !!this.messages.find(m => { return m.sender == user.userid || m.recipient == user.userid }) && user.userid !== this.user.userid
        })
      }

      users = users.filter(user => {
        return user.userid !== this.user.userid
      })

      if (this.search) {
        users = users.filter(user => {
          return user.firstName.toLowerCase().includes(this.search.toLowerCase())
        })
      }

      return users
    },
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'status.uploading': function (val) {
      if (!val) {
        this.submitForm()
      }
    },

    'toMessage': function (val) {
      if (val) {
        this.getRecipient(val)
      }
    },

    'user': function (user) {
      if (user && user.role == 'admin') {
        this.getUsers()
      }
    },
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    FileBlock
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('message', [
      'createMessage',
      'uploadFiles'
    ]),

    ...mapActions('users', [
      'getUsers'
    ]),
   
   ...mapActions('jobs', [
      'getEmployerJobs'
    ]),

    ...mapActions("apply", ['getApplicants']),

    setRecipient(recipient) {
      this.recipient = recipient
    },

    sendMessage() {
      if (this.$refs.messageForm.validate()) {
        if (this.files.length) {
          this.$store.commit('message/uploadingState', this.files.length)
          this.uploadFiles()
        }
        else {
          this.submitForm()
        }
      }
    },

    filesSelected(e) {
      e.forEach(file => {
        this.$store.commit('message/insertAttachments', file)
      })

      this.attachments = []
    },

    submitForm() {
      Promise.all([
        this.createMessage(this.recipient.userid)
      ])
      .then(() => {
        this.sent = true
      })
    },

    getRecipient(val) {
      Promise.all([
        this.$store.dispatch('users/getUserByUid', val)
      ])
      .then(() => {
        let user = this.users.find(u => u.userid == val)
        if (user) this.recipient = user
        if (this.subject) this.$store.commit('message/setSubject', this.subject)
        if (this.job) this.$store.commit('message/setJob', this.job)
      })
    },


  },

  mounted() {
    if (this.toMessage) this.getRecipient(this.toMessage)
    if (this.user && this.user.role == 'admin') this.getUsers()
    if (this.user && this.user.role == 'employer') {
      this.getApplicants()
      this.getEmployerJobs()
    }
  }
}
</script>