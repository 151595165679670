<template>
  <div v-if="message && !gettingReplies" class="msg-block d-flex gap-20 px-7 py-3">
    <div>
      <v-card class="pa-1 shadow-md">
        <UserPhoto
          :id="latestMessage ? latestMessage.sender : message.sender"
          photoSize="small"
          size="60"
          rounded
          tile
        />
      </v-card>
    </div>

    <div class="flex-grow-1 flex-wrap d-flex">
      <div class="d-flex full-width">
        <div style="width: 85%">
          <div class="heading-font mb-1 body-lg secondary--text font-weight-black">
            <router-link 
              :to="{ name: 'MessageData', params: { id: message.id } }" 
              class="text-decoration-none underline-on-hover"
            >
              {{ message.subject }}
            </router-link>

            <v-chip 
              v-if="checkNew(message)"
              class="ml-3 mt-n1 font-weight-medium"
              color="success" 
              x-small
            >New</v-chip>
          </div>
          <div class="body-1 mb-2">
            {{ brief(latestMessage ? latestMessage.message : message.message) }}
          </div>
        </div>
        <div class="flex-grow-1 text-right">
          <span class="primary--text" style="opacity: 0.3">
            {{ message.updated | fromNow }}
          </span>
        </div>
      </div>
      <div class="full-width d-flex align-center flex-wrap">
        <v-btn
          :to="{ name: 'MessageData', params: { id: message.id } }"
          :icon="$vuetify.breakpoint.xs"
          color="rgba(16,53,62,0.1)"
          class="mr-3 mr-sm-5"
          outlined
          small
        >
          <v-icon color="primary" :left="!$vuetify.breakpoint.xs">mdi-reply-outline</v-icon>
          <span class="primary--text d-none d-sm-inline">Reply</span>
        </v-btn>

        <message-count :messages="messages" :attachments="attachments" />

        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <div class="d-flex align-center">
          <v-btn v-if="!archived(message)" @click="starMessage(message)" :color="starred(message) ? 'yellow darken-1' : '#dadada'" icon>
            <v-icon small>{{ starred(message) ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
          </v-btn>
          
          <v-btn @click="archiveMessage(message)" color="#dadada" icon>
            <v-icon small>{{ archived(message) ? 'mdi-delete-restore' : 'mdi-trash-can-outline' }}</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageCount from './MessageCount'
import firebase from 'firebase'
import db from '@/firebase/init'
import { maxBy } from 'lodash'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      latests: [],
      gettingReplies: false,
      messages: { size: 0 },
      attachments: 0,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    MessageCount
  },

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: ['message'],

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    latestMessage: function () {
      if (!this.messages.size) return false
      else {
        let messages = []

        this.messages.forEach(doc => {
          let message = doc.data()
          message.id = doc.id
          message.ref = doc.ref
          messages.push(message)
        })
        
        return maxBy(messages, 'created')
      }
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    brief(message) {
      return message.length <= 100 ? message : `${message.substr(0, 100)}...`
    },

    checkNew(message) {
      let user = firebase.auth().currentUser
      if (user.uid == message.sender) return message.recipientNew
      else return message.senderNew
    },

    starred(message) {
      if (this.user) {
        if (this.user.userid == message.sender) {
          return !!message.senderStarred
        }
        else {
          return !!message.recipientStarred
        }
      }

      return false
    },

    archived(message) {
      if (this.user) {
        if (this.user.userid == message.sender) {
          return !!message.senderArchived
        }
        else {
          return !!message.recipientArchived
        }
      }

      return false
    },

    getReplies() {
      this.gettingReplies = true

      db.collection('messages')
      .doc(this.message.id)
      .collection('replies')
      .onSnapshot(snapshot => {
        this.attachments = 0
        this.messages = snapshot
        this.gettingReplies = false

        snapshot.forEach(doc => {
          doc.ref.collection('attachments')
          .get().then(snapshot => {
            if (snapshot.size) this.attachments += snapshot.size
          })
        })
      })
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getReplies()
  }
}
</script>